<template>
  <div>
    <ui-page-hero title="Configuration" />

    <div>
      <ChannelSettings />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    ChannelSettings: () => import('@/components/Channels/ChannelSettings'),
  },

  props: {},

  data() {
    return {}
  },

  computed: {},

  created() {},

  methods: {},
}
</script>

<style></style>
